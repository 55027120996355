<template>
    <div class="account-register-area ptb-100">
        <div class="container">
            <div class="account-register-content">
                <h2>Bienvenido a la Familia iCoin App</h2>
                <p>Registrate o Inicia Sesión en nuestra app, asi podras enviar dinero a tus amigos y familiares con la mejor tasa de cambio.</p>
                <a href="https://web.icoinapp.com/register" target="_blank" class="default-btn">
                    <i class='bx bxs-user'></i> 
                    Registrate Ahora
                </a>
                <a href="https://web.icoinapp.com/login" target="_blank" class="default-btn">
                    <i class='bx bxs-log-in'></i>
                    Iniciar Sesión
                </a>
            </div>
        </div>
        <div class="shape6"><img src="../../assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape7"><img src="../../assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape8"><img src="../../assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape9"><img src="../../assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape10"><img src="../../assets/img/shape/shape10.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'StartTrading'
}
</script>