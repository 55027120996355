<template>
    <div class="buy-sell-cryptocurrency-area bg-image pt-70">
        <div class="container">
            <div class="section-title">
                <h2>Tenemos Diferentes Opciones de Pago</h2>
                <p>Puedes realizar envios de dinero o recibir de tus amigos y familiares en mas de 10 países donde tenemos presencia actualmente.</p>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="buy-sell-cryptocurrency-image">
                        <img src="../../assets/img/women-with-tab.png" alt="image">
                    </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="buy-sell-cryptocurrency-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6 col-6 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon12.png" alt="image">
                                    </div>
                                    <h3>Transferencia Bancaria</h3>
                                    <p>Recibe en tus cuentas o envía a la cuenta de tus amigos y familiares.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-6 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon13.png" alt="image">
                                    </div>
                                    <h3>Billeteras Digitales</h3>
                                    <p>Recibe o paga desde las principales billeteras de tu país.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-6 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon14.png" alt="image">
                                    </div>
                                    <h3>Dinero en Efectivo</h3>
                                    <p>Recibe y paga en nuestras oficinas.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-6 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon21.png" alt="image">
                                    </div>
                                    <h3>Criptomonedas</h3>
                                    <p>Recibimos pagos en USDT.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-6 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon16.png" alt="image">
                                    </div>
                                    <h3>Sin Comisiones</h3>
                                    <p>La mayoría de nuestros procesos no tienen comisiones.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-6 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon17.png" alt="image">
                                    </div>
                                    <h3>Atención al Cliente</h3>
                                    <p>Tenemos un equipo excelente listo para ayudarte.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BuyAndSell'
}
</script>