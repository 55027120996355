<template>
    <div>
        <ValueTrade id="home" />
        <Navbar/>
        <PageTitle pageTitle="¡Uuups! Error 404" />
        <Funfacts id="payments" />
        <BuyAndSell />
        <GetStarted id="process" />
        <MobileApp id="download" />
        <StartTrading />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Footer from '../Layouts/Footer'
import Funfacts from "@/components/Common/Funfacts.vue";
import ValueTrade from "@/components/Layouts/ValueTrade.vue";
import BuyAndSell from "@/components/Home/BuyAndSell.vue";
import MobileApp from "@/components/Common/MobileApp.vue";
import GetStarted from "@/components/Home/GetStarted.vue";
import StartTrading from "@/components/Home/StartSending.vue";

export default {
    name: 'ContactPage',
    components: {
        StartTrading,
        GetStarted,
        MobileApp,
        BuyAndSell,
        ValueTrade,
        Funfacts,
        Navbar,
        PageTitle,
        Footer,
    }
}
</script>