import { createWebHistory, createRouter } from "vue-router";

import Home from "../components/Pages/Home";
import NotFound from "../components/Pages/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      reload: true,
    }
  },
  {
    path: '/:notFound',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
