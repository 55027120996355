<template>
    <div>
        <ValueTrade id="home" />
        <Navbar />
        <MainBanner />
        <Funfacts id="payments" />
        <BuyAndSell />
        <GetStarted id="process" />
        <ManagePortfolio />
        <Features />
        <MobileApp id="download" />
        <StartTrading />
        <Footer />
    </div>
</template>

<script>
import ValueTrade from '../Layouts/ValueTrade';
import Navbar from '../Layouts/Navbar';
import MainBanner from '../Home/MainBanner';
import Funfacts from '../Common/Funfacts';
import BuyAndSell from '../Home/BuyAndSell';
import GetStarted from '../Home/GetStarted';
import ManagePortfolio from '../Common/ManagePortfolio';
import Features from '../Common/Features';
import MobileApp from '../Common/MobileApp';
import StartTrading from '../Home/StartSending';
import Footer from '../Layouts/Footer';

export default {
    name: 'Home',
    components: {
        ValueTrade,
        Navbar,
        MainBanner,
        Funfacts,
        BuyAndSell,
        GetStarted,
        ManagePortfolio,
        Features,
        MobileApp,
        StartTrading,
        Footer,
    }
}
</script>