<template>
    <footer class="footer-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-footer-widget">
                        <router-link to="/" class="d-inline-block logo">
                            <img src="../../assets/img/logo-icoin-app.png" alt="Image">
                        </router-link>
                        <div class="app-download-content app-download-footer text-start">
                            <div class="btn-box">
                                <a href="https://play.google.com/store/apps/details?id=com.icoin.app" class="playstore-btn" target="_blank">
                                    <img src="../../assets/img/play-store.png" alt="image">
                                    Disponible en
                                    <span>Google Play</span>
                                </a>
                                <br>
                                <a href="https://apps.apple.com/app/icoin-app/id6480351025" class="applestore-btn" target="_blank">
                                    <img src="../../assets/img/apple-store.png" alt="image">
                                    Disponible en
                                    <span>App Store</span>
                                </a>
                            </div>
                        </div>
                        <ul class="social-links">
                            <li>
                                <a href="https://www.facebook.com/" target="_blank" class="facebook">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/" target="_blank" class="youtube">
                                    <i class='bx bxl-youtube'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.tiktok.com/" target="_blank" class="tiktok">
                                    <i class='bx bxl-tiktok'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/" target="_blank" class="instagram">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.twitter.com/" target="_blank" class="twitter">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-6">
                    <div class="single-footer-widget">
                        <h3>Cobertura</h3>
                        <ul class="services-links">
                            <li v-for="(country, key) in countries" :key="key">
                                <a :href="'https://web.icoinapp.com/register?country=' + country.code" target="_blank">{{ country.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Acceso Directo</h3>
                        <ul class="quick-links">
                            <li v-for="(item, key) in quick_links" :key="key">
                                <a :href="'https://web.icoinapp.com/' + item.link" target="_blank">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-footer-widget">
                        <h3>Contactenos</h3>
                        <ul class="footer-contact-info">
                            <li>Email: <a href="mailto:info@icoinapp.com" target="_blank">info@icoinapp.com</a></li>
                            <li>WhatsApp: <a href="https://api.whatsapp.com/send?phone=51991547365" target="_blank">+51 991 547 365</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="container">
                <p>Copyright <strong>iCoin App ©</strong> 2017 - {{currentYear}}. Todos los Derechos Reservados</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
            countries: [
                { name: 'Venezuela', code: 'VEN' },
                { name: 'Colombia', code: 'COL' },
                { name: 'Peru', code: 'PER' },
                { name: 'Chile', code: 'CHL' },
                { name: 'Ecuador', code: 'ECU' },
                { name: 'Brasil', code: 'BRA' },
                { name: 'Mexico', code: 'MEX' },
                { name: 'Panama', code: 'PAN' },
                { name: 'Estados Unidos', code: 'USA' }
            ],
            quick_links: [
                { name: 'Calculadora', link: 'calculator/public' },
                { name: 'Registro', link: 'register' },
                { name: 'Iniciar Sesion', link: 'login' },
                { name: 'Terminos y Condiciones', link: 'terms' }
            ]
        };
    }
}
</script>