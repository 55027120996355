<template>
    <div class="portfolio-area bg-e3e3e3">
        <div class="container">
            <div class="single-portfolio-item">
                <div class="row align-items-center m-0">
                    <div class="col-xl-5 col-lg-6 col-md-12 p-0">
                        <div class="content-slides">
                            <carousel 
                                :autoplay="5000"
                                :settings="settings"
                                :wrap-around="true"
                            >
                                <slide v-for="slide in carouselItems" :key="slide.id">
                                    <div class="content">
                                        <h3>{{slide.heading}}</h3>
                                        <p>{{slide.description}}</p>
                                    </div>
                                </slide>

                                <template #addons>
                                    <Navigation  />
                                </template>
                            </carousel>
                        </div>
                    </div>
                    <div class="col-xl-7 col-lg-6 col-md-12 p-0">
                        <div class="image text-center">
                            <img src="../../assets/img/portfolio/portfolio-img1.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'ManagePortfolio',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        carouselItems: [
            {
                id: 1,
                heading: 'Cotizador',
                description: 'En esta sección de nuestra aplicación podra ver la tasa actual y realizar pruebas antes de sus envíos y podrá obtener el valor que va a recibir tu beneficiario.',
            },
            {
                id: 1,
                heading: 'Historial',
                description: 'En nuestra aplicación tendrá un historial de todas sus transacciones, donde podrá validar el estado y monitorear los cambios en el proceso.',
            },
            {
                id: 1,
                heading: 'Contactos',
                description: 'Nuestra aplicación le permite almacenar los datos de sus beneficiarios, para hacer mas facil el proceso de envío.',
            },
        ],
        // carousel settings
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
    }),
})
</script>