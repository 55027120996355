<template>
    <div class="account-create-process-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-8 col-lg-9 col-md-12">
                    <div class="account-create-process-content">
                        <div class="section-title">
                            <h2>Empiece de Forma Facil y Sencilla</h2>
                            <p>A continuación te contamos lo facil que es registrarte y enviar con nosotros.</p>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-4 col-6 mdw-100 col-md-6">
                                <div class="single-process-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon1.png" alt="image">
                                    </div>
                                    <h3>Crea una cuenta</h3>
                                    <p>Registrate y completa el formulario para validar tu cuenta.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-6 mdw-100 col-md-6">
                                <div class="single-process-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon2.png" alt="image">
                                    </div>
                                    <h3>Calcula y Paga</h3>
                                    <p>Realiza el deposito a nuestras cuentas autorizadas para realizar tu envío.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-6 mdw-100 col-md-6">
                                <div class="single-process-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon3.png" alt="image">
                                    </div>
                                    <h3>Datos de Envío</h3>
                                    <p>Ingresa el pais destino, el valor a enviar y los datos del destinatario.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-3 col-md-12">
                    <div class="account-create-process-image text-center">
                        <img src="../../assets/img/convert-currency.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GetStarted'
}
</script>