<template>
    <div class="funfacts-area pt-70">
        <div class="container">
            <div class="row justify-content-center pb-5">
                <div class="col-4 col-sm-3 col-md-2 col-lg-1" v-for="item in countries" :key="item.code">
                    <img :src="require(`../../assets/img/country/${ item.code ?? '000' }.png`)" :alt="item.code">
                    <p class="text-center">{{ item.name }}</p>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div class="single-funfacts-box">
                        <div class="icon">
                            <img src="../../assets/img/icon-bg.png" alt="image">
                            <img src="../../assets/img/icon/icon8.png" class="main-icon" alt="image">
                        </div>
                        <h3>Transferencias Seguras</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div class="single-funfacts-box">
                        <div class="icon">
                            <img src="../../assets/img/icon-bg.png" alt="image">
                            <img src="../../assets/img/icon/icon9.png" class="main-icon" alt="image">
                        </div>
                        <h3>+100 Mil de Usuarios Felices</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div class="single-funfacts-box">
                        <div class="icon">
                            <img src="../../assets/img/icon-bg.png" alt="image">
                            <img src="../../assets/img/icon/icon10.png" class="main-icon" alt="image">
                        </div>
                        <h3>Mejor Tasa de Cambio</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div class="single-funfacts-box">
                        <div class="icon">
                            <img src="../../assets/img/icon-bg.png" alt="image">
                            <img src="../../assets/img/icon/icon11.png" class="main-icon" alt="image">
                        </div>
                        <h3>+10 Paises Conectados</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Funfacts',
    data(){
        return {
            countries: [
                { name: 'Perú', code: 'PER' },
                { name: 'Colombia', code: 'COL' },
                { name: 'Chile', code: 'CHL' },
                { name: 'Ecuador', code: 'ECU' },
                { name: 'EE. UU.', code: 'USA' },
                { name: 'Brasil', code: 'BRA' },
                { name: 'México', code: 'MEX' },
                { name: 'Panamá', code: 'PAN' },
                { name: 'Venezuela', code: 'VEN' }
            ]
        }
    }
}
</script>