<template>
    <div class="features-area pt-100">
        <div class="container">
            <div class="section-title">
                <h2>¿Porque Somos tu Mejor Opción?</h2>
                <p>Tenemos el mejor talento calificado para procesar tus envíos protegiendo tu dinero en cada transacción.</p>
            </div>
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-4 col-md-6 col-6 mdw-100">
                    <div class="single-features-box">
                        <img src="../../assets/img/features/features-img1.png" alt="image">
                        <h3>Protegemos tu Dinero</h3>
                        <p>Hacemos que tu beneficiario reciba el dinero rapido y seguro, nuestra tecnología mantiene seguras tus solicitudes en todo momento.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-6 mdw-100">
                    <div class="single-features-box">
                        <img src="../../assets/img/features/features-img2.png" alt="image">
                        <h3>Procesos Rápidos</h3>
                        <p>Procesamos tus solicitudes en el menor tiempo posible, así podemos garantizar que tu beneficiario pueda recibir el dinero rápidamente.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-6 mdw-100">
                    <div class="single-features-box">
                        <img src="../../assets/img/features/features-img3.png" alt="image">
                        <h3>Excelente Soporte</h3>
                        <p>Nuestro equipo siempre está atento para ayudarte y guiarte en todos los procesos de validación, envío y recepción de dinero.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>