<template>
	<div>
		<div class="currency-selection">
			<label>Tú envías</label>
			<input type="number" v-model="quantity" />
			<div class="dropdown">
				<button
					class="dropdown-toggle"
					@click="show = !show"
					:aria-pressed="show ? 'true' : 'false'"
					v-bind:class="{ show: button_show_state }"
					v-on:click="button_show_state = !button_show_state"
				>
					<img :src="require(`../../assets/img/country/${ sender.country ?? '000' }.png`)" :alt="sender.country"/>
					{{ sender.code }}
				</button>
				<ul class="dropdown-menu" :class="{ toggler: show }">
                    <div v-if="senders.length > 0">
                        <li v-for="(data, index) in senders" :key="index">
                            <div class="dropdown-item" v-on:click="sender = data, show = !show, button_show_state = !button_show_state">
                                <img :src="require(`../../assets/img/country/${ data.country ?? '000' }.png`)" :alt="data.country + '/' + data.code"/>
                                {{data.code}}
                            </div>
                        </li>
                    </div>
				</ul>
			</div>
		</div>
		<ul class="features-list">
			<li>
				<div class="d-flex align-items-center">
					<span class="first-span">
						<i class="fas fa-minus"></i>
                        Costo de envío
					</span>
					<span class="second-span">
						{{ Number(fee).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }} {{ sender.code }}
                    </span>
				</div>
			</li>
			<li>
				<div class="d-flex align-items-center">
					<span class="first-span">
						<i class="fas fa-equals"></i>
                        Monto a convertir
					</span>
					<span class="second-span">
						{{ Number(quantity - fee).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }} {{ sender.code }}
                    </span>
				</div>
			</li>
            <li>
                <div class="d-flex align-items-center">
					<span class="first-span">
						<i :class="'fas fa-' + (type === 'sell' ? 'divide' : 'times')"></i>
                        Tasa de cambio
					</span>
                    <span class="second-span">
						{{ Number(rate).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 7}) }}
                    </span>
                </div>
            </li>
            <li>
                <div class="d-flex align-items-center">
					<span class="first-span">
						<i class="fas fa-minus"></i>
                        Costo de Pago
					</span>
                    <span class="second-span">
						{{ Number(fee2).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }} {{ receiver.code }}
                    </span>
                </div>
            </li>
		</ul>
		<div class="currency-selection">
			<label>Tu contacto recibe</label>
			<input type="text" readonly :value="Number((type === 'sell' ? quantity / rate : quantity * rate) - fee2).toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 2})" />
			<div class="dropdown">
                <button
                    class="dropdown-toggle"
                    @click="show_r = !show_r"
                    :aria-pressed="show_r ? 'true' : 'false'"
                    v-bind:class="{ show_r: button_show_r_state }"
                    v-on:click="button_show_r_state = !button_show_r_state"
                >
                    <img :src="require(`../../assets/img/country/${ receiver.country ?? '000' }.png`)" :alt="receiver.country"/>
                    {{receiver.code}}
                    <br>
                    <p>{{ receiver.bank === '0134' ? 'Banesco' : (receiver.bank === '0000' ? 'Efectivo' : 'Todos') }}</p>
                </button>
                <ul class="dropdown-menu" :class="{ toggler: show_r }">
                    <div v-if="receivers.length > 0">
                        <li v-for="(data, index) in receivers" :key="index">
                            <div class="dropdown-item" v-on:click="receiver = data, show_r = !show_r, button_show_r_state = !button_show_r_state">
                                <img :src="require(`../../assets/img/country/${ data.country ?? '000' }.png`)" :alt="data.country + '/' + data.code"/>
                                {{data.code}}
                                <br>
                                <p>{{ data.bank === '0134' ? 'Banesco' : (data.bank === '0000' ? 'Efectivo' : 'Todos') }}</p>
                            </div>
                        </li>
                    </div>
                </ul>
			</div>
		</div>
		<a target="_blank" href="https://web.icoinapp.com/request">
			<button type="submit">
                <i class='bx bx-money-withdraw'></i>
				ENVIAR DINERO
			</button>
		</a>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: "MainBannerBox",
	data: () => ({
		show: false,
		active: false,
		button_show_state: false,
		button_active_state: false,
        show_r: false,
        active_r: false,
        button_show_r_state: false,
        button_active_r_state: false,
		//
        rates: [],
        fees: [],
        limits: [],
        //
        senders: [],
        receivers: [],
        sender: [],
        receiver: [],
        quantity: 0,
        fee: 0,
        type: null,
        rate: 0,
        fee2: 0,
        change: true
	}),
	mounted() {
		this.getCurrencies();
	},
    watch: {
        quantity() {
            if (this.quantity > 0) {
                this.getCalculate();
            }
        },
        sender() {
            this.change = true;
            this.getReceivers();
        },
        receiver() {
            this.getCalculate();
        }
    },
    methods: {
        getCurrencies() {
            axios.get('https://web.icoinapp.com/api/currencies/all').then((res) => {
                this.rates = res.data.rates;
                this.fees = res.data.fees;
                this.limits = res.data.limits;
                this.getSenders()
            }).catch((e) => {
                console.log(e);
            });

            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.getCurrencies, 600000);
        },
        getSenders() {
            const rates = this.rates;
            const sender = this.sender;
            let senders = [];
            for (let i = 0; i < rates.length; i++) {
                const a = {
                    country: rates[i].country_send,
                    code: rates[i].currency_send,
                    bank: rates[i].bank_send
                }
                let v = (JSON.stringify(senders)).includes(JSON.stringify(a));
                if (v === false) {
                    senders.push(a);
                }
            }
            this.senders = senders;
            if (sender.length === 0) {
                this.sender = senders[0];
            }
        },
        getReceivers() {
            const rates = this.rates;
            const sender = this.sender;
            const receiver = this.receiver;
            let receivers = [];
            for (let i = 0; i < rates.length; i++) {
                if (sender.country === rates[i].country_send &&
                    sender.code === rates[i].currency_send &&
                    sender.bank === rates[i].bank_send) {
                    const a = {
                        country: rates[i].country_receiver,
                        code: rates[i].currency_receiver,
                        bank: rates[i].bank_receiver
                    }
                    let v = (JSON.stringify(receivers)).includes(JSON.stringify(a));
                    if (v === false) {
                        receivers.push(a);
                    }
                }
            }
            this.receivers = receivers;
            if (receiver.length === 0 || sender === receiver) {
                this.receiver = receivers[0];
            }
            this.getCalculate();
        },
        getCalculate() {
            this.fee = 0;
            this.fee2 = 0;
            let min = [];
            let feeData = [];
            let feeData2 = [];
            let type = null;
            let rate = 0;
            let value = this.quantity;
            const rates = this.rates;
            const fees = this.fees;
            const limits = this.limits.min;
            const sender = this.sender;
            const receiver = this.receiver;
            for (let i = 0; i < limits.length; i++) {
                let data = limits[i].data;
                if (data.country === sender.country || (data.country === null && min.length === 0)) {
                    if (data.destiny === receiver.country || (data.destiny === null && min.length === 0)) {
                        if (data.currency === sender.code || (data.currency === null && min.length === 0)) {
                            min = data;
                        }
                    }
                }
            }
            if (value <= min.value && this.change) {
                value = min.value;
                this.quantity = min.value;
            }
            for (let i = 0; i < rates.length; i++) {
                if (sender.country === rates[i].country_send &&
                    sender.code === rates[i].currency_send &&
                    sender.bank === rates[i].bank_send &&
                    receiver.country === rates[i].country_receiver &&
                    receiver.code === rates[i].currency_receiver &&
                    receiver.bank === rates[i].bank_receiver) {
                    type = rates[i].type;
                    rate = rates[i].value;
                }
            }
            let result = type === 'sell' ? value / rate : value * rate;
            for (let i = 0; i < fees.length; i++) {
                if (fees[i].category === 'In') {
                    if (value > fees[i].range_min && value <= fees[i].range_max) {
                        if (fees[i].country_send === sender.country || (fees[i].country_send === null && feeData.length === 0)) {
                            if (fees[i].country_receiver === receiver.country || (fees[i].country_receiver === null && feeData.length === 0)) {
                                if (fees[i].currency === sender.code || (fees[i].currency === null && feeData.length === 0)) {
                                    feeData = fees[i];
                                }
                            }
                        }
                    }
                }
                if (fees[i].category === 'Out') {
                    if (result > fees[i].range_min && result <= fees[i].range_max) {
                        if (fees[i].country_send === sender.country || (fees[i].country_send === null && feeData2.length === 0)) {
                            if (fees[i].country_receiver === receiver.country || (fees[i].country_receiver === null && feeData2.length === 0)) {
                                if (fees[i].currency === receiver.code || (fees[i].currency === null && feeData2.length === 0)) {
                                    feeData2 = fees[i];
                                }
                            }
                        }
                    }
                }
            }
            if (feeData.type === '%') {
                this.fee = value * (feeData.value / 100);
            } else if (feeData.type === '$') {
                this.fee = feeData.value;
            }
            if (feeData2.type === '%') {
                this.fee2 = result * (feeData2.value / 100);
            } else if (feeData2.type === '$') {
                this.fee2 = feeData2.value;
            }
            this.type = type;
            this.rate = rate;
            this.change = false;
        }
    },
    unmounted() {
        clearTimeout(this.timeout);
    }
};
</script>